<template>
    <v-dialog
      max-width="500px"
      v-model="dialog"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          fab
          :top="!inline"
          :right="!inline"
          :absolute="!inline"
          small
          color="color3Text--text color3"
          @click.stop="btnClick"
          :loading="loading"
        >
          <v-icon small>fas fa-lock-alt</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar color="warning black--text">
          <v-toolbar-title>
            <v-icon>fas fa-exclamation-circle</v-icon>
            {{this.title || 'Are you sure?'}}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-center">
          <slot></slot>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="center">
              <v-btn
                color="success white--text"
                text
                @click.stop="unlock"
                :disabled="loading"
                :loading="loading"
              >Unlock</v-btn>
              <v-btn
                color="error white--text"
                text
                :disabled="loading"
                @click.stop="dialog = false"
              >Cancel</v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: ['inline', 'warn', 'title', 'closeMe'],
  data () {
    return {
      dialog: false,
      loading: false
    }
  },
  methods: {
    btnClick () {
      if (this.warn) {
        this.dialog = true
      } else {
        this.unlock()
      }
    },
    unlock () {
      this.loading = true
      this.$emit('unlock-click')
    }
  },
  watch: {
    closeMe: function (v) {
      if (v) this.dialog = false
    },
    dialog: function (v) {
      this.loading = false
    }
  }
}
</script>

<style>

</style>
