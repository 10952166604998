<template>
    <v-card tile flat>

      <v-tabs
        v-model="myTab"
        background-color="color2"
        slider-color="color3"
        tile center-active show-arrows dark
      >
        <v-tab href="#divisions" class="color2Text--text">
          Home
        </v-tab>
        <v-tab
          class="color2Text--text"
          v-for="(division) in tournament.divisionsWithTeams"
          :key="`division_${division.id}`"
          :href="`#division_${division.id}`"
        >
          {{division.name}}
        </v-tab>

        <!-- DIVISION TAB -->
        <v-tab-item value="divisions">
          <v-alert type="warning" :value="allReady && !allLocked">
            This tournament appears to be complete <v-btn @click.stop="lockResults">Lock All Results</v-btn>
          </v-alert>
          <v-card-text>
            <v-data-table
              :items="tournament.divisionsWithTeams"
              :headers="headers"
              hide-default-footer
              v-model="selected"
              :items-per-page="-1"
              dense
            >
              <template v-slot:item="{item}">
                <tr
                  @click="selectDivision(item)"
                >
                  <td>{{ item.name }}</td>
                  <td class="text-center">{{ item.teamCount }}</td>
                  <td class="text-center">
                    <v-avatar size="26px" color="white">
                      <v-icon v-if="!item.unFinishedTeams.length" class="green--text">far fa-check-circle</v-icon>
                      <v-icon v-else-if="item.finishedTeams.length" class="red--text">fas fa-exclamation-circle</v-icon>
                      <v-icon v-else>far fa-circle</v-icon>
                    </v-avatar>
                  </td>
                  <td class="text-center">
                    <v-icon v-if="item.complete">fas fa-lock-alt</v-icon>
                    <v-icon v-else>fas fa-lock-open-alt</v-icon>
                  </td>
                  <td class="text-right">
                    <v-btn
                      icon
                      fab small
                      color="color3 color3Text--text"
                    >
                      <v-icon>fas fa-caret-right</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-tab-item>

        <!-- TEAMS TAB -->
        <v-tab-item
          v-for="(division) in tournament.divisionsWithTeams"
          :key="`division_${division.id}`"
          :value="`division_${division.id}`"
        >
          <division-result-editor
            :active="myTab === `division_${division.id}`"
            :division="division"
          ></division-result-editor>
        </v-tab-item>

      </v-tabs>

      <v-snackbar
        vertical
        v-model="snackbar"
        :timeout="3000"
      >
        {{snackText}}
        <v-btn color="color3" text @click.native="snackbar = false">Close</v-btn>
      </v-snackbar>
    </v-card>
</template>

<script>
import DivisionResultsEditor from './DivisionResultsEditor.vue'

export default {
  props: ['tournament', 'color'],
  data () {
    return {
      saving: false,
      selected: [],
      selectedDivision: null,
      search: null,
      snackbar: false,
      snackText: null,
      myTab: null,
      headers: [
        {
          text: 'Division',
          align: 'left',
          sortable: true,
          value: 'divisionsString'
        },
        {
          text: 'Teams',
          align: 'center',
          sortable: true,
          value: 'teams.length'
        },
        {
          text: 'Ready',
          align: 'center',
          sortable: true,
          value: 'finishedTeams.length'
        },
        {
          text: 'Complete',
          align: 'center',
          sortable: true,
          value: 'complete'
        },
        {
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    allReady () {
      const unready = this.tournament.divisionsWithTeams.find(f => f.unFinishedTeams.length)
      return !unready
    },
    allLocked () {
      const unlocked = this.tournament.divisionsWithTeams.find(f => !f.complete)
      return !unlocked
    }
  },
  methods: {
    updateDisplay () {
      this.$refs.resultsDiplay.update()
    },
    selectDivision (division) {
      this.myTab = `division_${division.id}`
    },
    lockResults () {
      this.saving = true
      this.$VBL.tournament.lockResults(this.tournament.id)
        .then(r => {
          r.data && this.tournament.update(r.data)
          this.snackText = 'Results are locked'
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.saving = false
        })
    }
  },
  watch: {
    snackText: function (val) {
      if (val) this.snackbar = true
    }
  },
  components: {
    'division-result-editor': DivisionResultsEditor
  },
  mounted () {
    this.$root.$on('results-saved', (payload) => {
      this.snackText = `${payload} results have been saved`
    })
  }
}
</script>

<style>

</style>
